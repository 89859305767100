.topbar {
  background-color: transparent; /* Fully transparent background */
  box-shadow: none; /* Remove any shadow effects */
  z-index: 300;
  align-items: center;
}

.topbar .icon-button {
  margin-left: 10px;
  color: var(--icon-color, #666); /* Customize color */
  transition: color 0.3s;
}

.topbar .icon-button:hover {
  color: var(--icon-hover-color, #333); /* Add hover effect */
}

.topbar .logout-button {
  margin-left: 15px;
  color: var(--logout-color, red);
  font-size: 16px;
  transition: transform 0.2s;
}

.topbar .logout-button:hover {
  transform: scale(1.1); /* Highlight effect */
}
