/* outletSidebar.css */
.pro-sidebar-inner {
    background-color: #f9f9f9 !important;
  }
  
  .pro-icon-wrapper {
    background-color: transparent !important;
  }
  
  .pro-inner-item {
    padding: 5px 35px 5px 20px !important;
  }
  
  .pro-inner-item:hover {
    color: #868dfb !important;
  }
  
  .pro-menu-item.active {
    color: #6870fa !important;
  }
  
  .menu-item {
    margin: 10px 0 20px 0;
    color: #ffffff !important;
  }
  
  .menu-item .MuiTypography-root {
    font-size: 1.5rem;
    font-weight: bold;
  }
  
  .menu-item .MuiIconButton-root {
    color: #ffffff !important;
  }
  
  .menu-item .MuiSvgIcon-root {
    color: #ffffff !important;
  }
  
  .menu-item .MuiTypography-root:hover {
    color: #868dfb !important;
  }
  
  .menu-item.active {
    color: #6870fa !important;
  }
  
  .menu-item.active .MuiTypography-root {
    color: #6870fa !important;
  }
  