.topbar {
    background-color: #f9f9f9;
    color: #333333;
    padding: 10px 0; /* Adjust height by setting padding only on the top and bottom */
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 999; /* Ensure it's above other content */
}
  
  .topbar .search-box {
    background-color: #e0e0e0;
    border-radius: 3px;
  }
  
  .topbar .search-box input {
    padding-left: 10px;
    flex: 1;
  }
  
  .topbar .icons {
    display: flex;
  }
  
  .topbar .icon-button {
    padding: 8px;
    color: #333333 !important;
  }
  
  .topbar .icon-button:hover {
    background-color: #e0e0e0;
  }
  
  .topbar .logout-button {
    color: #d32f2f !important;
  }
