/* sidebar.css */
.sidebar {
    background-color: #f4f4f4;
    width: 250px;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    overflow-y: auto;
    z-index: 1000;
  }
  
  .sidebar ul {
    list-style: none;
    padding: 0;
    margin: 20px 0;
  }
  
  .sidebar li {
    padding: 10px;
  }
  
  .sidebar li a {
    text-decoration: none;
    color: #333;
  }
  
  .sidebar li a:hover {
    color: #666;
  }
  